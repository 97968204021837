import React, { useState } from 'react';
import Questionnaire from './Questionnaire';
import logo from './assets/logo.svg'; // Adjust the path as necessary

import './App.css'; // Assuming you have some global styles here


function App() {
  const [personName, setPersonName] = useState('');
const personData = {
        Paris: { 
          name: 'Paris Plain', 
          email: 'pplain@discoverultrium.com',
          tel: '2484171440',
          org: 'Ultrium',
          title: 'Founder, CEO',
          photoUrl: 'https://vcard.discoverultrium.com/images/avatar.514.png',
          url: 'https://discoverultrium.com' // Add the website URL
        },
        Justin: { 
          name: 'Justin Ayler', 
          email: 'jayler@discoverultrium.com',
          tel: '3134045822',
          org: 'Discover Ultrium',
          title: 'Co-Founder, CLO',
          photoUrl: 'https://example.com/images/paris.jpg',
          url: 'https://discoverultrium.com' // Add the website URL
        },
        Mario: { 
          name: 'Mario Patton', 
          email: 'mpatton@discoverultrium.com',
          tel: '8108444005',
          org: 'Ultrium',
          title: 'Co-Founder, CSO',
          photoUrl: 'https://vcard.discoverultrium.com/images/avatar.512.png',
          url: 'https://discoverultrium.com' // Add the website URL
        },
        Lindsey: { 
          name: 'Lindsey Vassallo', 
          email: 'Lvassallo@discoverultrium.com',
          tel: '2486864674',
          org: 'Ultrium',
          title: 'Oranizational Development Strategist',
          photoUrl: 'https://vcard.discoverultrium.com/images/avatar.513.png',
          url: 'https://discoverultrium.com' // Add the website URL
        },
        // Add more mappings as needed
      };
      const handleSelection = (selectedPersonKey) => {
        const person = personData[selectedPersonKey];
        if (person) {
          downloadVCard(person); // Now passing the entire person object
          setPersonName(person.name);
        } else {
          console.log("Person not found");
        }
      };
    
      function downloadVCard(person) {
        const vCardData = [
          'BEGIN:VCARD',
          'VERSION:3.0',
          `FN:${person.name}`, // Formatted Name
          `N:${person.name}`, // Structured Name, you might need to adjust this
          `EMAIL:${person.email}`,
          `TEL:${person.tel}`,
          `ORG:${person.org}`,
          `TITLE:${person.title}`,
          `PHOTO;ENCODING=b;TYPE=PNG:${person.photo}`, // Include the photo in the vCard
          `URL:${person.url}`, // Include the website URL in the vCard
          'END:VCARD'
        ].join('\n');
    
        const blob = new Blob([vCardData], { type: 'text/vcard' });
  const href = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = `${person.name}.vcf`; // The file name can be dynamic based on user input
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

    
      return (
        <div className="App">
          <img src={logo} alt="Logo" style={{ maxWidth: '200px', marginTop: '20px' }} />
          <Questionnaire onSelection={handleSelection} />
          {personName && (
            <div className="download-section">
              <p>{personName}'s vCard has been downloaded.</p>
            </div>
          )}
        </div>
      );
    }
    
    export default App;