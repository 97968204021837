import React, { useState } from 'react';
import './Questionnaire.css';


function Questionnaire({ onSelection }) {
  const [person, setPerson] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSelection(person);
  };

  return (
    <form onSubmit={handleSubmit} className="questionnaire">
      <label>
        Who did you speak with?
        <select value={person} onChange={(e) => setPerson(e.target.value)} required>
          <option value="">Select a person</option>
          <option value="Paris">Paris Plain</option>
          <option value="Justin">Justin Ayler</option>
          <option value="Mario">Mario Patton</option>
          <option value="Lindsey">Lindsey Vassallo</option>

          {/* Add more options as needed */}
        </select>
      </label>
      <button type="submit">Submit</button>
    </form>
  );
}

export default Questionnaire;
